/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesDreamRav: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Ravaged Realm</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_rav.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Ravaged Realm</h1>
          <h2>
            A guide for the Ravaged Realm mode in AFK Journey that gives you the
            best teams you can use!
          </h2>
          <p>
            Last updated: <strong>03/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Ravaged Realm" />
        <StaticImage
          src="../../../images/afk/generic/rav_intro.webp"
          alt="Guides"
        />
        <p>
          <strong>Ravaged Realm</strong> is a new mode released at the end of
          the Season 1 in AFK Journey. It's basically a twist on the Dream Realm
          mode, as you also have to face a massive raid boss here, with some
          additional restriction and quite decent rewards. What sets the mode
          apart from Dream Realm (or Primal Lord) are the team limitations as
          <strong>
            {' '}
            every day you can use only characters from a single faction
          </strong>{' '}
          to challenge the boss (with some small exceptions - some Cele/Hypo
          characters can also be used every day).
        </p>
        <p>
          In this guide, you will find best teams made for each faction that
          will help you raise your score and rank high in the Ravaged Realm
          mode!
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.
        </p>
        <p>
          Still, below you can find the team that worked best for us. Big thanks
          to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <h5>
          <AFKItem name="Graveborn" />
        </h5>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-g"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Whale team</>}
            className="with-bg"
          >
            <h5>Team #1 - Whale team</h5>
            <StaticImage
              src="../../../images/afk/rav/grave_1.webp"
              alt="Guides"
            />
            <p>Replacements</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="berial" enablePopover /> can
                be replaced by{' '}
                <AFKCharacter mode="inline" slug="silvina" enablePopover /> if
                you didn't invest into him.
              </li>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - F2P team</>}
            className="with-bg"
          >
            <h5>Team #2 - F2P team</h5>
            <StaticImage
              src="../../../images/afk/rav/grave_2.webp"
              alt="Guides"
            />
            <p>Replacements</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="salazer" enablePopover /> can
                be replaced by{' '}
                <AFKCharacter mode="inline" slug="niru" enablePopover />
              </li>
            </ul>
          </Tab>
        </Tabs>
        <br />
        <h5>
          <AFKItem name="Maulers" />
        </h5>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-m"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best team</h5>
            <StaticImage
              src="../../../images/afk/rav/mauler_1.webp"
              alt="Guides"
            />
            <p>Replacements</p>
            <ul className="bigger-margin">
              <li>
                No Cele/Hypos allowed this time, so there's just Maulers in the
                team,
              </li>
              <li>
                All replacements result in dps loss - some more than others,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> can be
                replaced by{' '}
                <AFKCharacter mode="inline" slug="satrana" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                be replaced by{' '}
                <AFKCharacter mode="inline" slug="koko" enablePopover />
              </li>
              <li>
                <AFKCharacter mode="inline" slug="seth" enablePopover /> can be
                replaced by{' '}
                <AFKCharacter mode="inline" slug="brutus" enablePopover />
              </li>
            </ul>
          </Tab>
        </Tabs>
        <br />
        <h5>
          <AFKItem name="Wilders" />
        </h5>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-w"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best team</h5>
            <StaticImage
              src="../../../images/afk/rav/wilder_1.webp"
              alt="Guides"
            />
            <p>Replacements</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="dionel" enablePopover />{' '}
                doesn't really perform well against this boss, even if you have
                him maxed, so don't bother using him,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> can
                be replaced by{' '}
                <AFKCharacter mode="inline" slug="eironn" enablePopover />
              </li>
            </ul>
          </Tab>
        </Tabs>
        <br />
        <h5>
          <AFKItem name="Lightbearers" />
        </h5>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-l"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best team (Whale)</>}
            className="with-bg"
          >
            <h5>Team #1 - Best team (Whale)</h5>
            <StaticImage
              src="../../../images/afk/rav/light_1.webp"
              alt="Guides"
            />
            <p>Replacements</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="mirael" enablePopover /> can
                be replaced by{' '}
                <AFKCharacter mode="inline" slug="walker" enablePopover />
              </li>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Best team (F2P)</>}
            className="with-bg"
          >
            <h5>Team #2 - Best team (F2P)</h5>
            <StaticImage
              src="../../../images/afk/rav/light_2.webp"
              alt="Guides"
            />
            <p>Replacements</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="mirael" enablePopover /> can
                be replaced by{' '}
                <AFKCharacter mode="inline" slug="walker" enablePopover />
              </li>
            </ul>
          </Tab>
        </Tabs>
        <br />
        <SectionHeader title="Videos" />
        <p>For more team ideas, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="hHt9qykG5Wg" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="0Tzr5Ju81nw" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="x4U-qj8Mlj0" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDreamRav;

export const Head: React.FC = () => (
  <Seo
    title="Ravaged Realm | AFK Journey | Prydwen Institute"
    description="A guide for the Ravaged Realm mode in AFK Journey that gives you the best teams you can use!"
    game="afk"
  />
);
